@font-face {
  font-family: 'ArialFont';
  src: url('assets/fonts/TimesNewRoman/arial.ttf') format('truetype');
}
@font-face {
  font-family: 'TimesNewRomanFont';
  src: url('assets/fonts/TimesNewRoman/times-new-roman.ttf') format('truetype');
}
@font-face {
  font-family: 'ArialBoldFont';
  src: url('assets/fonts/TimesNewRoman/arialbold.ttf') format('truetype');
}
